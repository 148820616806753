.homepage-bg-color {
  padding-top: 1px;
  background-color: var(--homepage-bg-color);
  
}


.borderConnect {
  border: 2px solid red;
  cursor: pointer;
  
  
}




.abc{
  width: 100%;
 
  
 
}


.homepage-border-bottom-color {
  border-bottom-color: var(--homepage-card-header-border-bottom-color);
}

/* Footer CSS  */
.homepage-footer {
  background-color: var(--homepage-footer-background);
  width: 100% !important;
  margin-top: 0;
}

.color{
  color: var(--color);
}
/* Light mode and dark mode  */

.light-mode-homepage {
  --homepage-bg-color: white;
  
  
  --color: #000c;

  /* homepage-footer */
  --homepage-footer-background: rgb(243 246 248);
  --nav-item-social-font: rgb(86 104 115);
  --footer-logo-text-style: rgb(0, 0, 0);
  --nav-item-font-small: rgb(86 104 115);
  --border: #333;
  --bord:#aa7b7b;
}

.dark-mode-homepage {
  --homepage-bg-color: #121615;
  --color:rgb(125, 129, 131);
  /* homepage-footer */
  --homepage-footer-background: rgb(27, 31, 30);
  --nav-item-social-font: rgb(255, 255, 255) !important;
  --footer-logo-text-style: rgb(255, 255, 255);
  --nav-item-font-small: rgb(255, 255, 255);
 
  --border: #808080;
  --bord:#aa7b7b;
}

.box{
  box-shadow: 0px 0px 4px var(--border);
}

.boxSender{
  box-shadow: 0px 0px 4px var(--bord);
}



@media (max-width: 415px) {

 
}



.dropdown .dropdown-btn{
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.08);
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;
}
.dropdown .dropdown-content{

  top: 110%;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.08);
  font-weight: bold;
  color: #333;
  font-weight: 500;
  left: 0;
  width: 100%;

}

.dropdown .dropdown-content .dropdown-item{
padding: 10px;
cursor: pointer;
transition:all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover{
  background: #f4f4f4;
}







.dropdownNew{
  
  position: relative;
  user-select: none;
}

.dropdownNew .dropdown-btnNew{
 
  
  font-weight: bold;
 
  align-items: center;
  justify-content: space-between;
 
  cursor: pointer;
}
.dropdownNew .dropdown-contentNew{
  position: absolute;
  font-weight: bold;
  color: #333;


  

}

.dropdownNew .dropdown-contentNew .dropdown-itemNew{
  padding: 10px;
cursor: pointer;
transition:all 0.2s;

}

.dropdownNew .dropdown-contentNew .dropdown-itemNew:hover{
  background: #bd7b7b;
}



@media screen and (max-width:478px) {

  .firstDiv{
    padding: auto;
    flex-direction: column;
  }
  .arrowRotate{
    
   align-items: center;
   
    
  }

  .rotatearrow{
    transform: rotateZ(90deg);
    font-weight: 600;
    width: 40px;
    height: 40px;
  }


  .abc {
    width:80%;
    padding: 20px;
    
  }

}