.block-page-bg-color {
  background-color: var(--block-page-bg-color);
}

.block-details-page-card-bg-color {
  background-color: var(--block-page-card-color) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.block-details-custom-container {
  margin-top: 5rem;
}

.block-details-heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--block-page-heading-text-color);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.block-details-arrow-icon {
  font-size: 24px;
  cursor: pointer;
  color: var(--block-page-card-header-text-color);
}

.block-details-cube-icon {
  font-size: 18px;
  color: var(--block-page-card-header-text-color);
  position: relative;
  top: -2px;
}

.block-details-block {
  font-size: 18px;
  position: relative;
  top: -1px;
  font-weight: 500;
  color: var(--block-page-card-header-text-color);
}

.block-details-block-number-heading {
  font-size: 1.5rem;
  color: var(--block-details-card-header-heading);
  font-weight: var(--light-mode-font-weight);
}

.block-details-block-number {
  margin-left: 120px;
}

.block-details-block-hash {
margin-left: 142px;
}
.block-details-node-number{
margin-left: 120px;
}
.block-details-no-of-transactions{
margin-left: 85px;
}
.block-details-total-fees{
margin-left: 148px;
}
.block-details-total-reward{
margin-left: 126px;
}
.block-details-fee-recipient{
margin-left: 123px;
}
.block-details-fee-recipient-with-address{
margin-left: 22px;
}
.block-details-block-rewrad{
margin-left: 121px;
}
.block-details-foundation-reward{
margin-left: 79px;
}
.block-details-burnt{
margin-left: 179px;
}
.block-details-timestamp{
margin-left: 179px;
}

.block-details-card-text-color {
  color: var(--block-details-card-data-text-color) !important;
  font-weight: var(--light-mode-font-weight-2);
}

.block-details-card-data-text-color {
  color: var(--block-details-card-content-text-color) !important;
  font-weight: var(--light-mode-font-weight-2);
}

.block-details-card-font-size {
  font-size: 15px !important;
}

@media  (max-width:991px) {
  .block-details-block-hash {
    margin-left: 0;
  }
}

@media  (max-width:767px) {
  .block-details-fee-recipient-with-address {
    margin-left: 0;
  }
}

@media  (max-width:500px) {

.block-details-block-number,
.block-details-node-number,
.block-details-no-of-transactions,
.block-details-total-fees,
.block-details-total-reward,
.block-details-fee-recipient,
.block-details-block-rewrad,
.block-details-foundation-reward,
.block-details-burnt,
.block-details-timestamp {
  margin-left: 0;
  display: block;
  margin-top: 5px;
}
}

.clickable-data-border {
  border: 1px solid var(--block-details-clickable-border-color);
  border-radius: 10px;
  padding: 5px;
  color: var(--block-details-clickable-data-color) !important;
  font-weight: var(--light-mode-font-weight-2);
  cursor: pointer;
}

.clickable-data-border:hover {
  background-color: var(--block-details-clickable-hover-color);
}

@media (max-width:441px) {
  .clickable-data-border {
    display: block;
  }
}

.block-details-transaction-table-clickable-data-border {
  border: 1px solid var(--block-details-clickable-border-color);
  border-radius: 10px;
  padding: 5px;
  color: var(--block-details-clickable-data-color) !important;
  font-weight: var(--light-mode-font-weight-2);
  cursor: pointer;
}

.block-details-transaction-table-clickable-data-border:hover{
  background-color: var(--block-details-clickable-hover-color);
}

.block-details-transaction-heading {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--block-page-heading-text-color);
}

.block-details-transaction-table-head {
  font-weight: var(--light-mode-font-weight);
  font-size: 12px;
  white-space: nowrap;
  color: var(--block-details-transaction-th-text-color) !important;
}

.block-details-table-data-font-size {
  font-size: 14px !important;
}

.block-details-transaction-table-font-style {
  font-weight: var(--light-mode-font-weight-2);
  white-space: nowrap;
  color: var(--block-details-transaction-table-text-color) !important;
}

.block-details-transaction-table tbody tr td {
  background-color: transparent ;
}

.block-details-transaction-table thead tr th {
  background-color: transparent ;
}

.block-details-transaction-table::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.block-details-transaction-table::-webkit-scrollbar-thumb {
  background: transparent;
}

.block-details-transaction-table::-webkit-scrollbar-track {
  background: transparent;
}

.block-details-transaction-table tbody tr.row-color:hover td {
  background-color: var(--block-details-transaction-table-row-hover-color) !important;
}

.block-details-border-bottom-color {
  border-bottom-color: var(--block-details-card-header-border-bottom-color);
}

/* Footer CSS  */
.blockdetails-footer {
  background-color: var(--block-page-footer-background);
  width: 100% !important;
}

.block-page-nav-item-font-small {
  color: var(--nav-item-font-small);
  font-size: 11px;
  font-weight: 500;
}

.block-page-nav-item-font {
  color: var(--nav-item-social-font) !important;
  font-size: 14px;
  font-weight: 500;
}

.block-page-nav-item-font-bold {
  font-weight: 600;
  font-size: larger;
  font-family: Audiowide, sans-serif !important;
  color: var(--footer-logo-text-style);
}

/* Dark mode and Light mode css  */

.light-mode-block-page {
  --block-page-bg-color:rgb(255, 255, 255);
  --block-page-heading-text-color:rgb(0,0,0);
  --block-page-card-color:rgba(213,212,208,0.2);
  --block-page-card-header-text-color:rgb(0,0,0);
  --block-details-card-header-heading:rgb(0,0,0);
  --light-mode-font-weight:600;
  --light-mode-font-weight-2:500;
  --block-details-card-data-text-color:#566873;
  --block-details-card-content-text-color:rgb(0,0,0);
  --block-details-clickable-border-color:lightgray;
  --block-details-clickable-data-color:#7e9bfc;
  --block-details-clickable-hover-color:aliceblue;
  --block-details-transaction-th-text-color:rgb(108, 117, 125);
  --block-details-transaction-table-text-color:#566873;
  --block-details-transaction-table-row-hover-color:rgb(250, 235, 215,0.2);
  --block-details-card-header-border-bottom-color:lightgray;

  /* block-page-footer */
  --block-page-footer-background: rgb(243 246 248);
  --nav-item-social-font: rgb(86 104 115);
  --footer-logo-text-style: rgb(0, 0, 0);
  --nav-item-font-small: rgb(86 104 115);
}

.dark-mode-block-page {
  --block-page-bg-color:#121615;
  --block-page-heading-text-color:rgb(255,255,255);
  --block-page-card-color:#1b1f1e;
  --block-page-card-header-text-color:rgb(255, 255, 255);
  --block-details-card-header-heading:rgb(6,247,247);
  --light-mode-font-weight:500;
  --light-mode-font-weight-2:400;
  --block-details-card-data-text-color:antiquewhite;
  --block-details-card-content-text-color:rgb(255,255,255);
  --block-details-clickable-border-color:rgba(6,247,247,0.5);
  --block-details-clickable-data-color:rgb(6,247,247);
  --block-details-clickable-hover-color:rgba(6,247,247,0.2);
  --block-details-transaction-th-text-color:antiquewhite;
  --block-details-transaction-table-text-color:rgb(255,255,255);
  --block-details-transaction-table-row-hover-color:rgba(6, 247, 247, 0.1);
  --block-details-card-header-border-bottom-color:rgba(6,247,247,0.3);

  /* block-page-footer */
  --block-page-footer-background: rgb(27, 31, 30);
  --nav-item-social-font: rgb(255, 255, 255) !important;
  --footer-logo-text-style: rgb(255, 255, 255);
  --nav-item-font-small: rgb(255, 255, 255);
}
