@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

body {
  font-family: "Inter", sans-serif ;
}

a {
  text-decoration: none;
}
.search-result {
  position: absolute;
  margin-left: 3px;
  width: 52%;
  background-color: var(--search-result-bg-color);
  padding: 10px;
  height: 42px;
  border: var(--search-result-border-color);
  border-radius: var(--bs-border-radius);
  cursor: pointer;
  --tw-shadow: 0px 0px 29px rgba(0, 0, 0, 0.13),
    0px 0px 44px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.2);
  --tw-shadow-colored: 0px 0px 29px var(--tw-shadow-color),
    0px 0px 44px var(--tw-shadow-color), 0px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: var(--search-result-text-color);
  overflow-x: auto;
  overflow-y: hidden;
}

.navbar-custom {
  height: 68px; 
  background: var(--background-header);
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  --tw-backdrop-blur: blur(32px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  --tw-shadow: 0px 5px 30px 0px rgba(86, 104, 115, .2), 0px 0px 0px 1px rgba(86, 104, 115, .03);
  --tw-shadow-colored: 0px 5px 30px 0px var(--tw-shadow-color), 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}

.navbar-custom .h-100 {
  height: 100%; /* Ensure the content fills the entire height of the navbar */
}

.logo {
  max-height: 100%; /* Ensure the logo fits within the navbar height */
}

.header-logo a {
  font-weight: 600;
  font-size: larger;
  font-family: Audiowide,sans-serif !important;
  --tw-text-opacity: 1;
  color: var(--logo-text-color);
}

.dropdown-button {
  font-weight: 500;
  font-size: medium;
  color: var(--dropdown-text-color) ;
  border: none;
}

.dropdown-button::after {
  display: none;
}

.dropdown-button:hover {
  background-color: var(--toggle-button-hover-bg-color);
  border-radius: 0.375rem;
}

.dropdown-item {
  color: var(--dropdown-text-color) !important;
  font-weight: 500;
  font-size: 14px;
}

.dropdown-item:hover{
  background-color: var(--dropdown-item-hover-color);
  width: 95%;
  border-radius: 5px;
}

.dropdown-icon {
  position: relative;
  bottom: 1.8px;
}

.header-page-card-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.dropdown-menu {
  background-color: var(--background-dropdown);
  border: none;
}

.form-control {
  background-color: var(--form-control-background);
  border-color: var(--form-control-border-color);
  color: var(--input-color);
}

.form-control:focus {
  background: var(--form-control-focus-bg);
  color: var(--form-control-focus-color);
}

.light-mode-header-page input::placeholder {
  color: var(--place-holder-text-color-light);
}

.dark-mode-header-page input::placeholder {
  color: var(--place-holder-text-color-dark);
}

.toggle-mode {
  color: var(--toggle-button-color);
}

/* Your styles for light mode */
.light-mode-header-page {
  --background-header:#fff6;
  --logo-text-color: black;
  --dropdown-text-color:rgb(0,113,149);
  --toggle-button-color:black;
  --background-dropdown : white;
  --toggle-button-hover-bg-color:#fffc;
  --place-holder-text-color-light:black;
  --dropdown-item-hover-color:#f8f9fa;
  --form-control-border-color:lightgrey;
  --form-control-background:transparent;
  --form-control-focus-bg:transparent;
  --form-control-focus-color:black;
  --search-result-text-color:rgb(21 82 123);
  --search-result-bg-color:rgb(255 255 255);
  --search-result-border-color:none;
  --input-color:black;
}

/* Your styles for dark mode */
.dark-mode-header-page {
  --background-header:rgb(18, 22, 21);
  --logo-text-color: rgb(6, 247, 247);
  --dropdown-text-color:rgb(6, 247, 247);
  --toggle-button-color:rgb(6, 247, 247);
  --background-dropdown : black;
  --toggle-button-hover-bg-color:rgb(6, 247, 247);
  --place-holder-text-color-dark:rgb(6, 247, 247);
  --dropdown-item-hover-color:grey;
  --form-control-border-color:rgb(6, 247, 247);
  --form-control-background:transparent;
  --form-control-focus-bg:rgb(18, 22, 21);
  --form-control-focus-color:rgb(6, 247, 247);
  --search-result-text-color:rgb(6, 247, 247);
  --search-result-bg-color:rgb(18, 22, 21);
  --search-result-border-color:1px solid rgb(6, 247, 247);
  --input-color:rgb(6, 247, 247);

}

.form-check-input[type="checkbox"]:checked {
  background-color: #06f7f7; 
  border-color: #06f7f7; 
}



